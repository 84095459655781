// fetchConfigurationsBootstrap.js
'use strict';

import getMeData from '../selector/getMeData.js';

import setConfigurations from '../action/setConfigurations.js';
import setABTestConfig from '../action/setABTestConfig.js';

import fetch from '../resource/customFetch.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import { reduxStoreInitializePromise } from '../resource/reduxStoreInitializePromise.js';
import {
  CONFIG_PRIORITY_PRESENCE_USER,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch configurations bootstrap
 * @kind action
 * @param {string} {priority} - config priority.
 * @return {Promise} Action promise.
 */
const fetchConfigurationsBootstrap =
  ({ priority } = {}) =>
  async (dispatch, getState) => {
    // guarantee have the latest store
    await reduxStoreInitializePromise;

    const networkingSelectPath = ['fetch', 'bootstrap', priority];
    const token = getMeData(getState(), 'token');

    if (CONFIG_PRIORITY_PRESENCE_USER === priority && !token) {
      return dispatch({ type: '' });
    }

    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
        ...(CONFIG_PRIORITY_PRESENCE_USER === priority
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    };

    const fetchUrl = getResourceUrl({ endpoint: '/configurations/bootstrap' });

    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath: networkingSelectPath },
    });
    try {
      let response = await fetch(fetchUrl.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl,
          shouldIgnoreHeaderAuthorizationOnRetryFetch:
            CONFIG_PRIORITY_PRESENCE_CLIENT === priority,
        });
      }
      const payload = (await response.json()) || {};
      await dispatch(
        setABTestConfig({
          abToken: payload.ab,
          priority,
        })
      );
      if (payload['configuration-group-id']) {
        await dispatch(
          setConfigurations({
            configData: {
              'configuration-group-id': payload['configuration-group-id'],
              'configuration-groups': payload['configuration-groups'],
            },
            priority,
          })
        );
      }
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default fetchConfigurationsBootstrap;
