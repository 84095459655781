// getCurrentLanguage.js
'use strict';
import i18n, { localeConvertor } from '../resource/i18n.js';

const TRANSLATION_KEY_PREFIX = 'language_';

/**
 * Get current language
 * @return {string} return current language code.
 */
const getCurrentLanguage = () => {
  const language = i18n.language?.toLowerCase()?.replace(/_/, '-');

  if (language === 'zh-hans') {
    return 'zh-CN';
  } else if (language === 'zh-hant') {
    return 'zh-TW';
  }

  return i18n.language;
};

/**
 * Get current faq language
 *
 * @param {object} {i18n} - i18n instance from `withTranslation()`
 * @param {array} {[supportedLanguages = ['en', 'zh-TW']]} - supported faq languages.
 *
 * @return {string} return current language code.
 */
export const getCurrentFaqLanguage = ({
  i18n,
  i18nLanguage,
  supportedLanguages = ['en', 'zh-TW'],
} = {}) => {
  let language = i18nLanguage || i18n?.language || 'zh-TW';

  if (supportedLanguages.includes(language)) {
    return language.toLowerCase();
  }

  if (language.startsWith('zh')) {
    const lowerCasedLanguage = language.toLowerCase()?.replace(/_/, '-');
    if (['zh-hans', 'zh-cn', 'zh-hans-cn'].includes(lowerCasedLanguage)) {
      language = 'zh-CN';
    } else {
      language = 'zh-TW';
    }
  }

  if (supportedLanguages.includes(language)) {
    return language;
  }

  supportedLanguages.forEach(supportedLanguage => {
    if (
      supportedLanguage.replace(/-.*$/g, '') === language.replace(/-.*$/g, '')
    ) {
      language = supportedLanguage;
    }
  });

  if (supportedLanguages.includes(language)) {
    return language;
  }

  return supportedLanguages[0];
};

/**
 * Get language lokalise key
 * @param {string} {language} - language code
 * @return {string} return assigned language translation key
 */
export const getLanguageTranslationKey = ({ language }) => {
  const lang = localeConvertor({
    locale: language,
    isISO639: true,
  }).toLowerCase();

  if (lang === 'zh-hans') {
    return `${TRANSLATION_KEY_PREFIX}zh`;
  }
  return `${TRANSLATION_KEY_PREFIX}${lang.replace(/\W/, '_')}`;
};

export default getCurrentLanguage;

/**
 * Get supported language code.
 * @param {string} {language} - language code.
 * @param {array} {supportedLanguages} - supported language codes.
 * @return {string} Return supported langauge
 */
export const getSupportedLanguage = ({
  language = '',
  supportedLanguages = [],
}) => {
  let lang = language;
  if (language === 'zh') {
    lang = 'zh-CN';
  }
  const wholeMatched = supportedLanguages.find(code => {
    if (code === lang) {
      return code;
    }
    return null;
  });
  const partMatched = supportedLanguages.find(code => {
    if (lang.startsWith(code.replace(/-[a-z]+/i, ''))) {
      return code;
    }
    return null;
  });
  if (wholeMatched) {
    lang = wholeMatched;
  } else if (partMatched) {
    lang = partMatched;
  } else {
    lang = 'en';
  }
  return lang;
};
